import React from "react";
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
//import { StaticImage } from "gatsby-plugin-image";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const PageNews = ({ data, location }) => {
  const intl = useIntl();
  const params = new URLSearchParams(location.search);
  const category = params.get("category");
  const posts = data.allContentfulMediaRoomNews.edges;

  return (
    <Layout location={location} mode="beige">
      <Seo
        title={
          intl.locale === "en"
            ? `News I Cyberdyne Care Robotics GmbH`
            : `Neuigkeiten I Cyberdyne Care Robotics GmbH`
        }
        description={
          intl.locale === "en"
            ? `Keep up to date with everything that is going on at Cyberdyne Care Robotics GmbH.`
            : `Seien Sie über alles, was bei Cyberdyne Care Robotics passiert, informiert.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/1w0VtVuhZsng5XiHy1xYJF/c9bfc1236811562182aac62919ac4919/E181_191.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-bge">
        <div className="container container-newslist">
          <h1 className="h1">
            {intl.locale === "en" ? `See what's new` : `Was gibt es Neues?`}
          </h1>

          <p className="message-label-bold txt txt-rot mb-8">
            {intl.locale === "en"
              ? `News, pressrelease, event, exhibition`
              : `News, pressrelease, event, exhibition`}
          </p>

          <p className="newslist_sidebar__title">
            {intl.locale === "en" ? `Choose Category` : `Auswahlmöglichkeiten`}
          </p>
          <ul className="newslist_sidebar">
            <li className="font-weight-bold active">
            <Link to="/news/?category=News">
                <span>{intl.locale === "en" ? `News` : `Neuigkeiten`}</span>
              </Link>
            </li>
            <li className="font-weight-bold">
              <Link to="/news/?category=Presslease">
                <span>
                  {intl.locale === "en"
                    ? `Press release`
                    : `Pressemitteilungen`}
                </span>
              </Link>
            </li>
            <li className="font-weight-bold">
            <Link to="/news/?category=Event">
                <span>
                  {intl.locale === "en" ? `Event` : `Veranstaltungen`}
                </span>
              </Link>
            </li>
            <li className="font-weight-bold">
            <Link to="/news/?category=Exhibition">
                <span>{intl.locale === "en" ? `Exhibition` : `Messen`}</span>
              </Link>
            </li>
          </ul>

          <div className="news-card-wrap flex-row flex-wrap mx-n2">
            {posts &&
              posts.map(({ node: post }) => {
                if (category) {
                  if (category === post.category) {
                    return (
                      <div key={post.slug} className="flex-column-4 p-2">
                        <div className="news-card">
                          <p className="slide-title__data font_inter">
                            {post.createdAt} | {post.category}
                          </p>
                          <h2 className="title">{post.title}</h2>
                          <p className="excerpt">
                            {post.content &&
                              documentToPlainTextString(
                                JSON.parse(post.content.raw)
                              ).substr(0, 120)}
                          </p>
                          <div className="news-link">
                            <Link to={`/news/${post.slug}`} className="">
                              {intl.locale === "en" ? `Read` : `Lesen`}
                            </Link>
                          </div>
                          {/* {
                      post.thumbnail && <div className="newslist_post__imageWrapper"><img src={post.thumbnail.fixed.src} /></div>
                    } */}
                        </div>
                      </div>
                    );
                  } else{
                    return ("")
                  }
                } else {
                  return (
                    <div key={post.slug} className="flex-column-4 p-2">
                      <div className="news-card">
                        <p className="slide-title__data font_inter">
                          {post.createdAt} | {post.category}
                        </p>
                        <h2 className="title">{post.title}</h2>
                        <p className="excerpt">
                          {post.content &&
                            documentToPlainTextString(
                              JSON.parse(post.content.raw)
                            ).substr(0, 120)}
                        </p>
                        <div className="news-link">
                          <Link to={`/news/${post.slug}`} className="">
                            {intl.locale === "en" ? `Read` : `Lesen`}
                          </Link>
                        </div>
                        {/* {
                    post.thumbnail && <div className="newslist_post__imageWrapper"><img src={post.thumbnail.fixed.src} /></div>
                  } */}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 txt mb-5">
            {intl.locale === "en" ? `Media Room` : `Multimedia`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Clinical Publications`
                    : `Klinische Studien`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The significant existence of technology is to support, assist,
  enhance, and regenerate functions of humans.`
                    : ``}
                </p>
                <Link to="/research/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Recruiting Inc.` : `Karriere`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `We envision a future where humans and technology are
  seamlessly interfaced, feeding off of each other.`
                    : ``}
                </p>
                <Link to="/recruit/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
};

export default PageNews;

export const pageQuery = graphql`
  query ($language: String) {
    allContentfulMediaRoomNews(
      limit: 50
      filter: {
        slug: { ne: "do-not-delete-sample" }
        node_locale: { eq: $language }
      }
      sort: {fields: createdAt, order: DESC}
    ) {
      edges {
        node {
          slug
          content {
            raw
          }
          category
          updatedAt
          title
          createdAt(formatString: "DD. MMMM YYYY")
        }
      }
    }
  }
`;
